import { Scene } from "phaser";
import store from "@/store";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossImage = data.bossImage;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.difficulty = data.difficulty;
    this.lampTime = data.lampTime;
    this.puzzle = data.puzzle;
  }

  selectedOption = null;

  create() {
    this.bgSound = this.sound.add("bgm");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;
    this.bgSound.play();

    this.scene.launch(this.puzzle ? "PuzzleScene" : "IntroScene", {
      noOfRounds: this.noOfRounds,
      noOfCuts: this.noOfCuts,
      score: 0,
      round: 0,
      qCount: 0,
      avatar: this.avatar,
      name: this.name,
      questions: this.questions,
      friend: this.friend,
      difficulty: this.difficulty,
      countCorrect: 0,
      bossImage: this.bossImage,
      env: this.env,
      currentCampTileX: Math.floor(Math.random() * 5),
      nextCampTileX: Math.floor(Math.random() * 5),
      totalBones: (this.noOfRounds - 1) * 2,
      collectedBones: this.puzzle ? 4 : 0,
      collectedSkull: 0,
      lampTime: this.lampTime,
    });
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
