import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
// import _ from "lodash";
import Phaser from "phaser";

export default class MachineScene extends Scene {
  constructor() {
    super({ key: "MachineScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.bossImage = data.bossImage;
    this.currentCampTileX = data.currentCampTileX;
    this.nextCampTileX = data.nextCampTileX;
    this.totalBones = data.totalBones;
    this.collectedBones = data.collectedBones;
    this.collectedSkull = data.collectedSkull;
    this.lampTime = data.lampTime;
  }

  playerAvatarObject = [];

  create() {
    this.registerSounds();

    this.screen1 = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setDepth(99);
    this.screen2 = this.add
      .rectangle(240, 427, 480, 854, 0xffffff)
      .setDepth(99)
      .setAlpha(0);

    this.imgBackground = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);

    this.machine = this.add
      .sprite(350, 520, "sptMachine")
      .setFrame(0)
      .setDepth(3);

    this.boneBoard = this.add
      .image(250, 670, "imgBoneBrd")
      .setOrigin(0)
      .setDepth(7);

    this.BoneText = this.add
      .text(350, 710, "0 / " + this.totalBones, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#fff",
      })
      .setOrigin(0, 0.5)
      .setDepth(7);

    this.boneIV = this.add
      .image(280, 685, "imgBone4")
      .setScale(0.22)
      .setOrigin(0)
      .setDepth(7);
    this.boneI = this.add
      .image(268, 702, "imgBone1")
      .setScale(0.17)
      .setOrigin(0)
      .setDepth(7);

    this.anims.create({
      key: "machineAnim",
      frames: this.anims.generateFrameNumbers("sptMachine", {
        frames: [
          1, 2, 3, 2, 2, 3, 2, 3, 2, 3, 3, 2, 2, 3, 2, 2, 1, 1, 2, 3, 2, 2, 3,
          2, 3, 2, 3, 3, 2, 2, 3, 2, 2, 1, 0,
        ],
      }),
      frameRate: 10,
    });

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 620, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 620, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 620, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 1000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: this.screen1,
      alpha: 0,
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {
        this.tempBones = ["imgBone1", "imgBone2", "imgBone3"];

        for (let i = 0; i < this.collectedBones; i++) {
          if (i == this.collectedBones - 1) {
            if (this.collectedSkull > 0) {
              let bone = this.add
                .image(120, 600, "imgBone4")
                .setScale(0.3)
                .setOrigin(0);
              this.time.delayedCall(200 * i, () => {
                this.throw(bone, 320, 500);
                this.time.delayedCall(1000, () => {
                  this.BoneText.setText(i + 1 + " / " + this.totalBones);
                });
              });
            } else {
              let bone = this.add
                .image(
                  120,
                  600,
                  this.tempBones[
                    Math.floor(Math.random() * this.tempBones.length)
                  ]
                )
                .setScale(0.3)
                .setOrigin(0);
              this.time.delayedCall(200 * i, () => {
                this.throw(bone, 320, 500);
                this.time.delayedCall(1000, () => {
                  this.BoneText.setText(i + 1 + " / " + this.totalBones);
                });
              });
            }

            if (this.collectedBones < this.totalBones) {
              this.time.delayedCall(2000, () => {
                // this.npcSpeech = this.add
                //   .image(350, 450, "imgSpeech")
                //   .setOrigin(0.5, 1)
                //   .setDepth(3)
                //   .setScale(0.5);

                // this.add
                //   .image(350, 440, "imgExclMark")
                //   .setScale(0.4)
                //   .setOrigin(0.5, 1)
                //   .setDepth(3);

                if (this.playerMask) {
                  this.playerMask.destroy();
                }
                this.playerMask = this.add
                  .image(
                    this.playerAvatarObject[0].x,
                    this.playerAvatarObject[0].y,
                    "maskSad"
                  )
                  .setScale(0.25)
                  .setDepth(6);
              });

              this.time.delayedCall(3000, () => {
                this.sfxBossFaild.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxBossFaild.play();
                this.notEnoughBones();
              });
            } else {
              this.time.delayedCall(i * 200 + 1500, () => {
                this.machine.play("machineAnim");
                this.sfxMachine.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxMachine.play();
                this.time.delayedCall(5000, () => {
                  this.sfxBossSuccess.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.sfxBossSuccess.play();
                });
                for (let j = 0; j < 9; j++) {
                  let puz = this.add.image(320, 500, "imgPuz").setOrigin(0);
                  this.time.delayedCall(200 * j + 5000, () => {
                    if (this.playerMask) {
                      this.playerMask.destroy();
                    }
                    this.playerMask = this.add
                      .image(
                        this.playerAvatarObject[0].x,
                        this.playerAvatarObject[0].y,
                        "maskHappy"
                      )
                      .setScale(0.25)
                      .setDepth(6);
                    this.throw(puz, 600, 400);
                    this.tweens.add({
                      targets: this.screen2,
                      alpha: 1,
                      duration: 500,
                      delay: 1000,
                      ease: "Sine.easeInOut",
                      onComplete: () => {
                        this.time.delayedCall(500, () => {
                          this.scene.start("PuzzleScene", {
                            avatar: this.avatar,
                            name: this.name,
                            round: this.round,
                            score: this.score,
                            questions: this.questions,
                            friend: this.friend,
                            difficulty: this.difficulty,
                            countCorrect: this.countCorrect,
                            noOfRounds: this.noOfRounds,
                            noOfCuts: this.noOfCuts,
                            bossImage: this.bossImage,
                            currentCampTileX: this.nextCampTileX,
                            nextCampTileX: this.nextNextCampTileX,
                            totalBones: this.totalBones,
                            collectedBones: this.collectedBones,
                            collectedSkull: this.collectedSkull,
                            lampTime: this.lampTime,
                          });
                        });
                      },
                    });
                  });
                }
              });
            }
          } else {
            let bone = this.add
              .image(
                120,
                600,
                this.tempBones[
                  Math.floor(Math.random() * this.tempBones.length)
                ]
              )
              .setScale(0.3)
              .setOrigin(0);
            this.time.delayedCall(200 * i, () => {
              this.throw(bone, 320, 500);
              this.time.delayedCall(1000, () => {
                this.BoneText.setText(i + 1 + " / " + this.totalBones);
              });
            });
          }
        }
      },
    });
  }

  registerSounds() {
    this.sfxThrow = this.sound.add("sfxThrow");
    this.sfxMachine = this.sound.add("sfxMachine");
    this.sfxStartMachine = this.sound.add("sfxStartMachine");
    this.sfxBossFaild = this.sound.add("sfxBossFaild");
    this.sfxBossSuccess = this.sound.add("sfxBossSuccess");
  }

  notEnoughBones() {
    this.scoreDetails = [];
    this.scoreDetails.push(
      this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.6).setDepth(9)
    );

    this.scoreDetails.push(
      this.add.image(240, 420, "imgSettingPanel").setScale(0.9).setDepth(9)
    );

    this.scoreDetails.push(
      this.add.image(240, 395, "imgBone4").setScale(0.3).setDepth(9)
    );

    this.scoreDetails.push(
      this.add.image(212, 413, "imgBone1").setScale(0.3).setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(240, 465, i18n.t("miniGames.dino.notEnough"), {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#492a12",
          align: "center",
        })
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(240, 320, i18n.t("miniGames.dino.oops"), {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 24,
          color: "#492a12",
          align: "center",
        })
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .image(240, 510, "imgOptionBtn")
        .setScale(0.2, 0.25)
        .setDepth(9)
        .setInteractive({ useHandCursor: true })
        .on(
          "pointerdown",
          () => {
            router.push({
              name: "GameTestingScore",
              query: { isboss: 0 },
            });
          },
          this
        )
    );

    this.scoreDetails.push(
      this.add
        .text(240, 510, i18n.t("miniGames.general.ok"), {
          fontSize: "20px",
          fill: "#000",
          fontFamily: "Sassoon, sans-serif",
        })
        .setDepth(9)
        .setOrigin(0.5)
    );
  }

  throw(bone, ex, ey) {
    const startX = bone.x;
    const startY = bone.y;
    const endX = ex;
    const endY = ey;

    const duration = 1000;
    const height = 500;

    this.tweens.add({
      targets: [bone],
      x: endX,
      y: endY,
      duration: duration,
      ease: "Linear",
      onStart: () => {
        this.sfxThrow.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master *
            0.5
        );
        this.sfxThrow.play();
      },
      onUpdate: (tween, target) => {
        const progress = tween.progress;
        const curveX = Phaser.Math.Interpolation.Bezier(
          [startX, (startX + endX) / 2, endX],
          progress
        );
        const curveY = Phaser.Math.Interpolation.Bezier(
          [startY, startY - height, endY],
          progress
        );
        target.x = curveX;
        target.y = curveY;
      },
      onComplete: () => {},
    });
  }

  update() {
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }
  }
}
